const Close = ({className, color = "#85CEE3"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <rect width="28" height="28" fill={color} />
      <rect
        x="7.47266"
        y="8.53418"
        width="1.50678"
        height="16.1674"
        transform="rotate(-45 7.47266 8.53418)"
        fill="black"
      />
      <rect
        x="18.8652"
        y="7.43457"
        width="1.60417"
        height="16.1674"
        transform="rotate(45 18.8652 7.43457)"
        fill="black"
      />
    </svg>
  );
};

export default Close;
