import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchSetPlaces } from "../actions/places";
import { fetchSetTags } from "../actions/tags";
import { fetchSetArticles } from "../actions/articles";
import { fetchSetCities } from "../actions/cities";
import { fetchSetExperiences } from "../actions/experiences";
import { fetchSetCircuits } from "../actions/circuits";
import { fetchSetMices } from "../actions/mice";
import { fetchSetStories } from "../actions/stories";
import { fetchsetUserDetails } from "../actions/user";
import { fetchSetInstaWallImages } from "../actions/instawall";
import { fetchSetPlacesStats, fetchSetToursStats } from "../actions/stats";
import { setLocal } from "../actions/local";
import { supportedLocal } from "../helpers";
import i18n from "../i18n";

function LayoutComponent({
  fetchSetPlaces,
  fetchSetTags,
  fetchSetCities,
  setLocal,
  fetchSetMices,
  fetchSetExperiences,
  fetchSetArticles,
  fetchSetStories,
  local,
  fetchsetUserDetails,
  fetchSetPlacesStats,
  fetchSetToursStats,
  fetchSetCircuits,
  fetchSetInstaWallImages,
}) {
  useEffect(() => {
    if (local) {
      i18n.changeLanguage(local);
    }
  }, [local]);

  useEffect(() => {
    fetchSetPlaces();
  }, [fetchSetPlaces]);

  useEffect(() => {
    if (!local) {
      setLocal(supportedLocal());
    }
  }, [setLocal, local]);

  useEffect(() => {
    fetchSetTags();
  }, [fetchSetTags]);

  useEffect(() => {
    fetchSetCities();
  }, [fetchSetCities]);

  useEffect(() => {
    fetchSetExperiences();
  }, [fetchSetExperiences]);

  useEffect(() => {
    fetchSetMices();
  }, [fetchSetMices]);

  useEffect(() => {
    fetchSetArticles();
  }, [fetchSetArticles]);

  useEffect(() => {
    fetchsetUserDetails();
  }, [fetchsetUserDetails]);

  useEffect(() => {
    fetchSetStories();
  }, [fetchSetStories]);

  useEffect(() => {
    fetchSetCircuits();
  }, [fetchSetCircuits]);

  useEffect(() => {
    fetchSetInstaWallImages();
  }, [fetchSetInstaWallImages]);

  useEffect(() => {
    fetchSetPlacesStats();
    fetchSetToursStats();
  }, [fetchSetPlacesStats, fetchSetToursStats]);

  return <div />;
}

const mapStateToProps = (state) => {
  return {
    ...state.Places,
    ...state.Tags,
    ...state.Filters,
    ...state.Local,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetPlaces: () => dispatch(fetchSetPlaces()),
    fetchSetTags: () => dispatch(fetchSetTags()),
    fetchSetCities: () => dispatch(fetchSetCities()),
    fetchSetExperiences: () => dispatch(fetchSetExperiences()),
    fetchSetMices: () => dispatch(fetchSetMices()),
    fetchSetArticles: () => dispatch(fetchSetArticles()),
    setLocal: (local) => dispatch(setLocal(local)),
    fetchsetUserDetails: () => dispatch(fetchsetUserDetails()),
    fetchSetStories: () => dispatch(fetchSetStories()),
    fetchSetCircuits: () => dispatch(fetchSetCircuits()),
    fetchSetInstaWallImages: () => dispatch(fetchSetInstaWallImages()),
    fetchSetPlacesStats: () => dispatch(fetchSetPlacesStats()),
    fetchSetToursStats: () => dispatch(fetchSetToursStats()),
  };
};

const appConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutComponent);

export default appConnect;
