/**
 *
 * @param {*} evt
 * @param {*} cityName
 */

import { API_STATS_LIVE, CDN_ENDPOINT, NO_IMAGE } from "./constants";


export function validateEmail(email) {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export function tripMenu(evt, cityName) {
  var i, tabcontent, tablinks, vertical, vertical2;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("checkbox-tag-2");
  vertical = document.getElementsByClassName("vertical");
  vertical2 = document.getElementsByClassName("vertical2");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" checkedtrip", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " checkedtrip";
  if (cityName === "Preferences") {
    vertical[0].classList.remove("hidden");
    vertical2[0].className += " hidden";
  } else if (cityName === "Destinations") {
    vertical2[0].classList.remove("hidden");
    vertical2[0].className += " hidden";
    vertical[0].className += " hidden";
  } else if (cityName === "Dates") {
    vertical2[0].classList.remove("hidden");
    vertical[0].className += " hidden";
  }
}

export function bottomMenu(evt) {
  var i, bottomTab;

  bottomTab = document.getElementsByClassName("mobilemenu");
  for (i = 0; i < bottomTab.length; i++) {
    bottomTab[i].className = bottomTab[i].className.replace(" active", "");
  }
  evt.currentTarget.className += " active";
}

export function getSafe(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

export function citySlugToName(slug, cities, local) {
  return (
    getSafe(() => cities.filter((c) => c.slug === slug)[0].title[local]) || slug
  );
}

export function cdnImage(url, width="w_800", extension='f_jpg') {
  if (!url) return NO_IMAGE;
  const urlOne = url.replace('https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads',
    CDN_ENDPOINT)
  
  return urlOne.replace(CDN_ENDPOINT,
    "https://cdn.visitbenimellal.com/fetch/c_scale,fl_progressive,q_auto," + width + "/"+extension+"/" + CDN_ENDPOINT)
  
 
}




/**
 * https://stackoverflow.com/questions/40774697/how-to-group-an-array-of-objects-by-key
 * const cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }];
 * const result = groupBy(cars, (c) => c.make);
 * 
 */

 export function groupBy(xs, f) {
  // eslint-disable-next-line
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k.toLowerCase()] || (r[k.toLowerCase()] = [])).push(v), r), {});
}



export function findCommonElements(arr1, arr2) {
  try {
    return arr1.some((item) => arr2.includes(item));
  } catch (e) {
    return false;
  }
}
export function meterDisplay(m) {
  const realLifeDistanceApprox = m*1.3 
  if (realLifeDistanceApprox < 1000) {
    return realLifeDistanceApprox.toFixed(0) + " m";
  }

  if (realLifeDistanceApprox >= 1000) {
    return (realLifeDistanceApprox / 1000).toFixed(2) + " km";
  }
}
export function slugify(text, local) {
  if (local === "ar" && text) return text.replace(" ", "-");
  try {
    return text
      .toString()
      .toLowerCase()
      .replace(new RegExp("[àáâãäå]", "g"), "a") // handling diactritics
      .replace(new RegExp("æ", "g"), "ae")
      .replace(new RegExp("ç", "g"), "c")
      .replace(new RegExp("[èéêë]", "g"), "e")
      .replace(new RegExp("[ìíîï]", "g"), "i")
      .replace(new RegExp("ñ", "g"), "n")
      .replace(new RegExp("[òóôõö]", "g"), "o")
      .replace(new RegExp("œ", "g"), "oe")
      .replace(new RegExp("[ùúûü]", "g"), "u")
      .replace(new RegExp("[ýÿ]", "g"), "y")
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w]+/g, "") // Remove all non-word chars
      .replace(/\+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  } catch (e) {
    return "default";
  }
}
// calculte nbr of days btw two dates
export function diffInDays(dateOne, dateTwo) {
  if (!dateOne || !dateTwo) return 1
  // eslint-disable-next-line
  {
    /** THIS IS TEMPORARY FIX */
    /** PROBLEM: Calendar format is dd/mm/yyyy */
    /** New Date require a yyyy-mm-dd */
  }
  const timeOne = new Date(dateOne.split('/').reverse().join('-')).getTime();
  const timeTwo = new Date(dateTwo.split('/').reverse().join('-')).getTime();
  const diff = Math.round((timeTwo - timeOne) / (1000 * 3600 * 24));
  if (diff > 0) {
    return diff;
  }
  return 1;
}
export function feedBi(data) {
  fetch(API_STATS_LIVE, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (e) {
      console.log("__BI__SYNC__ERROR");
    });
}

//temporaray fix until I find a toast lib
export const message = {
  error: (e) => console.error(e),
  success: (s) => console.log(s),
};

//implement this
export function supportedLocal() {
  //const current = getLang()

  //if (SUPPORTED_LOCALS.indexOf(current) > -1) {
  //  return current
  //}
  return "en";
}


export function getCardinal(angle, isInstruction=false) {
  if (typeof angle === 'string') angle = parseInt(angle);
  if (angle < 0 || angle > 360 || typeof angle === 'undefined') return null;

  switch (Math.round(angle / 22.5)) {
    case 1:
        return isInstruction ? "keep_ahead" : "NNE" ;
    case 2:
        return isInstruction ?  "keep_ahead" :  "NE";
    case 3:
        return isInstruction ? "right" : "ENE";
    case 4:
        return isInstruction ? "right" : "E";
    case 5:
        return isInstruction ? "right" : "ESE";
    case 6:
        return isInstruction ? "u_turn" : "SE";
    case 7:
        return isInstruction ? "u_turn" : "SSE";
    case 8:
        return isInstruction ? "u_turn" : "S";
    case 9:
        return isInstruction ? "u_turn" : "SSW";
    case 10:
        return isInstruction ? "u_turn" :"SW";
    case 11:
        return isInstruction ? "left" : "WSW";
    case 12:
        return isInstruction ?  "left" : "W";
    case 13:
        return isInstruction ?  "left" :"WNW";
    case 14:
        return isInstruction ?  "left" : "NW";
    case 15:
        return isInstruction ? "keep_ahead" : "NNW";
    default:
        return isInstruction ? "keep_ahead" : "N";
}

}