const RightArrow = () => {
  return (
    <svg
      width="7"
      height="9"
      viewBox="0 0 7 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.74996 1.00559L5.49902 4.37979L1.74996 7.75391"
        stroke="black"
        strokeWidth="1.5996"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};


export default RightArrow