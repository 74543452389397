import { API_QUOTES, JSON_HEADERS } from '../constants'
import { message } from '../helpers';


export const ADD_TO_QUOTE = 'ADD_TO_QUOTE'
export const SET_QUOTES = 'SET_QUOTES'
export const FLUSH_QUOTES = 'FLUSH_QUOTES'
export const SET_QUOTE_META = 'SET_QUOTE_META'

function setQuotes(quotes) {
    return {
        type: SET_QUOTES,
        payload: quotes
    }
}

export function setQuoteMeta(meta) {
    return {
        type: SET_QUOTE_META,
        payload: meta
    }
}

export function addToQuote(quote) {
    return {
        type: ADD_TO_QUOTE,
        payload: quote
    }
}


export function flushAllQuotes() {
    return {
        type: FLUSH_QUOTES
    }
}

// get places from the server
export function fetchSetQuotes(id) {
    return function (dispatch) {
        return fetchApiGetQuotes(id)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(setQuotes(response.data.messages))
                        dispatch(setQuoteMeta(response.data))
                    } else {
                        message.error('Something bad happens. Code: QE01')
                        if (response.message) {
                            message.error(response.message)
                        }
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: QE02'))
    }
    
}


function fetchApiGetQuotes(id) {
    return fetch(`${API_QUOTES}/${id}`,{
        method: "GET",
        headers: {...JSON_HEADERS},
    })
}

// update places from the server
export function fetchUpdateQuotes(body) {
    return function (dispatch) {
        return fetchApiUpdateQuote(body)
                .then(function(res){ return res.json(); })
                .then(function (response) {
                    if (response && response.data) {
                        dispatch(fetchSetQuotes(response.data._id))
                    } else {
                        message.error('Something bad happens. Code: QU01')
                        if (response.message) {
                            message.error(response.message)
                        }
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: QU02'))
    }
    
}



function fetchApiUpdateQuote(/*{from, message}*/body) {
    return fetch(`${API_QUOTES}/${body.id}`,{
        method: "PUT",
        body: JSON.stringify(body),
        headers: {...JSON_HEADERS},
    })
}


// update places from the server
export function fetchPostQuote(body) {//body={from, to, message}
    return function (dispatch) {
        return fetchApiPostQuote(body)
                .then(function(res){ return res.json(); })
            .then(function (response) {
                if (response && response.data) {
                    message.success('Demande de devis envoyé!')
                        //history.push('/')
                        //window.location.replace(`/quotes/${response.data._id}/${response.data.obfMembers[0]}`)
                        //dispatch(addToQuote(response.data))
                    } else {
                        message.error('Something bad happens. Code: QP01')
                        if (response.message) {
                            message.error(response.message)
                        }
                    }
                })
                .catch(e=>message.error('Something bad happens, we are aware of the problem and working right now to fix it. Code: QP02'))
    }
    
}

function fetchApiPostQuote(body) {
    return fetch(API_QUOTES,{
        method: "POST",
        body: JSON.stringify(body),
        headers: {...JSON_HEADERS},
    })
}