import React from "react";
import animatedLogo from '../images/loading.mp4'

function Loading() {
  return (
    <div className="loadingimg">
    <video loop autoPlay muted  className='__video'>
        <source src={animatedLogo} type="video/mp4" />
    </video>
    </div>
  );
}

export default Loading;
