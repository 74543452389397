import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { Provider } from "react-redux";
import configureStore from "./store";
import App from "./layout/App";
import ErrorBoundary from "./components/ErrorBoundary";
import "./i18n";
import "react-lazy-load-image-component/src/effects/blur.css";
// import "./App.css";
import "./sass/app.scss";
import "mapbox-gl/dist/mapbox-gl.css";

const { store, persistor } = configureStore();

const instance = createInstance({
  urlBase: "https://analytics.ctd.ma",
  siteId: 1,
});

const Main = () => {
  return (
    // <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MatomoProvider value={instance}>
            <App />
          </MatomoProvider>
        </PersistGate>
      </Provider>
    // </ErrorBoundary>
  );
};

export default Main;
