const Pause = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" stroke="white" />
      <rect x="16" y="13" width="3" height="13" fill="white" />
      <rect x="21" y="13" width="3" height="13" fill="white" />
    </svg>
  );
};

export default Pause;
