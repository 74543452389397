import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import {createBrowserHistory} from 'history'
import storage from 'localforage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers'
import { PERSISTANCE_KEY } from './constants'


const persistConfig = {
  key: PERSISTANCE_KEY,
  storage,
  stateReconciler: autoMergeLevel2
}

const combinedReducers = combineReducers({
  ...rootReducer,
  router: routerReducer,
  blackList:['Quotes']
})


const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const persistedReducer = persistReducer(persistConfig, combinedReducers)
const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function store() {
  let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares)))
  
  let persistor = persistStore(store)

  return { store, persistor, history }
}
