import React from "react";
import ReactDOM from "react-dom";
import Main from './Main'
import * as serviceWorker from "./serviceWorkerRegistration";



ReactDOM.render(
<Main />,
  document.getElementById("root")
);

serviceWorker.register();