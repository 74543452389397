const LeftArrow = () => {
  return (
    <svg
      width="7"
      height="9"
      viewBox="0 0 7 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25004 7.99441L1.50098 4.62021L5.25004 1.24609"
        stroke="black"
        strokeWidth="1.5996"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftArrow;
