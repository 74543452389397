import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import "./Modal.styles.scss";
import AppStore from "./localAssets/AppStore.png";
import GooglePlay from "./localAssets/GooglePlay.png";
import phone1 from "./localAssets/phone1.png";
import phone2 from "./localAssets/phone2.png";
import Shapes from "./localAssets/Shapes";
import { Close } from "../../Icons";
import { useTranslation } from "react-i18next";
import { APP_STORE_URL, GOOGLE_PLAY_URL } from "../../constants";

const MyModal = ({ show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body className="modal-container" style={{ padding: 0 }}>
        <div className="closeButton" onClick={onClose}>
          <Close />
        </div>
        <div className="screens">
          <Image src={phone1} alt="screen-1" className="phone1" />
          <Shapes />
        </div>
        <Image src={phone2} alt="screen-2" className="phone2" />

        <div className="content">
          <h1>{t("modalTitle")}</h1>
          <p>{t("modalDesc")}</p>

          <div className="actions">
            <a href={APP_STORE_URL}>
              <Image src={AppStore} alt="app-store" />
            </a>
            <a href={GOOGLE_PLAY_URL} target="_blank">
              <Image src={GooglePlay} alt="google-play" />
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MyModal;
