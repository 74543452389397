const initState = {
  events: [],
  currentEvent: 0,
};

export default function eventsReducer(state = initState, action) {
  switch (action.type) {
    case "SET_EVENTS":
      return {
        ...state,
        events: action.payload,
      };
    case "SET_CURRENT_EVENT":
      return {
        ...state,
        currentEvent: action.payload,
      };
    default:
      return state;
  }
}
