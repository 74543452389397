import imagenondisponible from "./images/imagenondisponible.png";
import blurryImage from "./images/blurry.jpg";
export const PERSISTANCE_KEY = "BMK-024";
export const TOUR_BUILDER_DEFAULT_NBR_DAYS = 4;
export const API_ENDPOINT = "https://api.visitbenimellal.com";
export const DEV_API_ENDPOINT = "http://127.0.0.1:4040";
export const CDN_ENDPOINT = "https://d3lq8p6p6r1qxf.cloudfront.net";
export const USER_IP_ENDPOINT = "https://json.geoiplookup.io";
export const API_NEWSLETTER = API_ENDPOINT + "/newsletter";
export const API_CONTACT = API_ENDPOINT + "/contact";

/**
 * Vote Endpoind Schema
 *
 * id: {
      type: String,
    },
    type: {
      type: Number, //circuit=03, place = 01, tour=02
    },
    like: {
      type: Boolean
    }
 */
export const API_VOTES = API_ENDPOINT + "/vote";
export const API_PLACES = API_ENDPOINT + "/cache/places";
export const API_MICE = API_ENDPOINT + "/mice";
export const API_QUOTES = API_ENDPOINT + "/quote";
export const API_ARTICLES = API_ENDPOINT + "/articles";
export const API_DEALS = API_ENDPOINT + "/cache/places?isDeal=true";
export const API_TAGS = API_ENDPOINT + "/tags";
export const API_CITIES = API_ENDPOINT + "/city";
export const API_CIRCUITS = API_ENDPOINT + "/circuits";
export const API_TOURS = API_ENDPOINT + "/tours";
export const API_STATS = API_ENDPOINT + "/cache/bi";
export const API_STORIES = API_ENDPOINT + "/stories";
export const API_INSTAWALL = API_ENDPOINT + "/instawall";
export const API_STATS_LIVE = API_ENDPOINT + "/bi";
export const API_AUTH = API_ENDPOINT + "/auth/login";
export const API_AUTH_IS_LOGGED_IN = API_ENDPOINT + "/auth/isLoggedIn"; //Not implemented yet
export const API_UPLOAD = API_ENDPOINT + "/media";
export const CLOUDINARY =
  "https://cdn.visitbenimellal.ma/fetch/c_fit,g_center,h_600/";
export const MAPBOX_STYLE =
  "mapbox://styles/casbahdev/clg55cs3r003401o6xkm7274p";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiY2FzYmFoZGV2IiwiYSI6ImNsZzU0NGZ4eDAxaDgzbm90N3doeG9lMXYifQ.N92Svf7rjH1NSKgn2k-R2w";
export const MAPBOX_ACCESS_TOKEN = {
  accessToken:
    "pk.eyJ1IjoiY29tZXBpYyIsImEiOiJjazB5bTQ1emEwZnFjM2lwaGUzcTA4Y2d6In0.uNqxPdD-ijZeEZd5RxN_oA",
};
export const MAPBOX_STYLE_URL =
  "mapbox://styles/comepic/ckqr0s5iw1wkb18qyprvnrmpz";
export const NO_IMAGE = imagenondisponible;
export const BLURRY_IMAGE = blurryImage;

//exclude from tripPlanner
export const TRIP_PLANNER_ARRAY = [
  "activite",
  "restaurant",
  "evenement",
  "transport",
  "hotel",
  "agence-de-voyages",
  "cafe",
  "packages",
  "visite-guidee",
  "hotel-connect",
  "circuit-decouvertes",
];

//exact main menu Tags
export const EXACT_MAIN_MENU_TAGS = [
  "culture",
  "nature",
  "balneaire",
  "activite",
  "evenement",
  "agence-de-voyage",
];

//exact deal tags
export const EXACT_DEAL_TAGS = [
  "hebergement-deal",
  "activite-deal",
  "sejour-deal",
  "restaurant-deal",
];

//exact trip planner tags
export const EXACT_TRIP_PLANNER_ARRAY = [
  "culture",
  "nature",
  "balneaire",
  "adrenaline",
  "activites-aquatiques",
  "activites-sportives",
];
export const BROCAT_ARRAY = [
  { fr: "ligne de bus", en: "bus line", es: "línea de bus", ar: "خط الباص" },
  {
    fr: "plan de development",
    en: "development plan",
    ar: "خطة التنمية",
    es: "plan de Desarrollo",
  },
  {
    fr: "carte touristique",
    en: "tourist card",
    ar: "بطاقة سياحية",
    es: "tarjeta de turista",
  },
  { fr: "catalogue", en: "catalog", ar: "فهرس", es: "catalogar" },
];

export const BROCHURES_ARRAY = [
  { fr: "papiers", en: "papers", ar: "ورق", es: "papel" },
  { fr: "électronique", en: "electronic", ar: "الكتروني", es: "electrónico" },
];
export const EAT_ARRAY = ["gastronomie"];

//todos arrays
export const TODO_ARRAY = ["activite"];

// See Tags
export const SEE_ARRAY = ["culture", "nature", "balneaire"];

export const ALL_ARRAY = [
  "culture",
  "nature",
  "balneaire",
  "adrenaline",
  "activites-aquatiques",
  "activites-sportives",
];

//include to Explore menu
export const EXPLORE_ARRAY = [
  "nature",
  "histoire",
  "activite",
  "shopping",
  "nightlife",
];

export const JSON_HEADERS = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
};

export const filter =
  "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 23.78%), radial-gradient(53.73% 53.73% at 42.09% 46.27%, rgba(0, 0, 0, 0.1) 60.43%, rgba(0, 0, 0, 0.2) 99.48%)";

export const SUPPORTED_LOCALS = {
  fr: "Français",
  es: "Español",
  en: "English",
  ar: "العربية",
  tn: "ⵜⵉⴼⵉⵏⴰⵖ",
};

export const DEFAULT_MAP_CENTER = [-6.374509, 32.327291];

export const SECTIONS_DEF = {
  story: "story",
  topExperience: "topExperience",
  regionMap: "regionMap",
  circuit: "circuit",
  regionMapMultiSelect: "regionMapMultiSelect",
  hoverMap: "hoverMap",
  storyEvent: "storyEvent",
  instaWall: "instaWall",
  tripPlanner: "tripPlanner",
  hero: "hero",
  localwords: "localwords",
};


export const APP_STORE_URL = "#"
export const GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.visitbenimellal.app"
export const INSPIRING_URL= "https://inspiration.visitbenimellal.com/"
export const INTERACTIVE_MAP_URL = "https://map.visitbenimellal.com/"